import React, { useContext, createContext, useRef } from "react";
import "./refund.scss";
import { useToggle } from "../../../hooks/useToggle";
import { useToggleWithData } from "../../../hooks/useToggleWithData";
import InformationIcon from "../../../assets/img/information.png";
import CustomSelect from "../../common/customSelect";
import { CUSTOM_SELECT_COLOR_WHITE } from "../../common/customSelect/constants";
import { CBUComponent } from "./cbu";
import axios from "axios";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FormikContext, useFormik } from "formik";
import validate from "./validate";
import FileInput from "./../../common/file-input-component";
import { Button, Tooltip } from "react-bootstrap";
import { avatarGroupClasses, TextField } from "@mui/material";
import { ModalConfirmChangeComponent } from "./confirm-change";
import { useState } from "react";
import { MessageContext } from "./context";
import { OverlayTrigger } from "react-bootstrap";
import { myAxios, fetchLogs } from "../../../utils/fetch";
import { InformacionComponent } from "../refund/informacion";
import { familyGroupFilterPSHB } from "../../../hooks/familyGroupFilter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const AddRefundComponent = () => {
  const CBUToggle = useToggle();
  const MessageToggle = useToggle();
  const [responseData, setResponseData] = useState({
    message: "",
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [money, setMoney] = useState(true);

  const informacionToggle = useToggle();

  const ref_orden_fecha = useRef();
  const ref_orden_periodo = useRef();
  const ref_observacion = useRef();
  const { user } = useContext(UserGlobalContextMemorySpace);
  const [contracts, setContracts] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [temporalCategories, setTemporalCategories] = React.useState([]);
  const [familygroup, setFamilyGroup] = React.useState([]);
  const [discList, setDiscList] = React.useState([]);
  const [tiposAdjuntos, setTiposAdjuntos] = React.useState([]);
  const [temporalTiposAdjuntos, setTemporalTiposAdjuntos] = React.useState([]);
  const [archivos, setArchivos] = React.useState({});
  const [banks, setBanks] = React.useState();
  const [mostrarTooltipDiscapacidad, setMostrarTooltipDiscapacidad] =
    React.useState(false);

  // CLOSE
  const [closeCBU, setCloseCBU] = React.useState(false);
  const [closeCategoria, setCloseCategoria] = React.useState(false);
  const [closeIntegrante, setCloseIntegrante] = React.useState(false);
  const [closeDiscapacidad, setCloseDiscapacidad] = React.useState(false);
  const [closeTipoDocumento, setCloseTipoDocumento] = React.useState(false);
  // END CLOSE

  //CLICK
  const clickCategoria = () => {
    setCloseCBU(true);
    setCloseDiscapacidad(true);
    setCloseIntegrante(true);
    setCloseCategoria(false);
    setCloseTipoDocumento(true);
  };

  const clickCBU = () => {
    setCloseCBU(false);
    setCloseDiscapacidad(true);
    setCloseIntegrante(true);
    setCloseCategoria(true);
    setCloseTipoDocumento(true);
  };

  const clickDiscapacidad = () => {
    setCloseCBU(true);
    setCloseDiscapacidad(false);
    setCloseIntegrante(true);
    setCloseCategoria(true);
    setCloseTipoDocumento(true);
  };

  const clickIntegrante = () => {
    setCloseCBU(true);
    setCloseDiscapacidad(true);
    setCloseIntegrante(false);
    setCloseCategoria(true);
    setCloseTipoDocumento(true);
  };

  const clickTipoDocumento = () => {
    setCloseCBU(true);
    setCloseDiscapacidad(true);
    setCloseIntegrante(true);
    setCloseCategoria(true);
    setCloseTipoDocumento(false);
  };
  //END CLICK

  // const [aplicaDisca,setAplicaDisca]=React.useState(false);
  const [cbuSelected, setCbu] = React.useState({
    id: "",
    name: "Seleccionar",
  });
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const dateFormat =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    (date.getDate() - 1).toString().padStart(2, "0");
  const dateFormatPeriod =
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getFullYear();
  const [maxDate, setMaxDate] = React.useState(dateFormat);
  date.setMonth(date.getMonth() - 6);
  date.setDate(date.getDate() - 1);

  var archivosInvalidos = [];

  // const dateFormat6 = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0")
  // const [minDate, setMinDate] = React.useState(dateFormat6);

  const [periodoSelected, setPeriodo] = React.useState(dateFormat);
  const [periodoSelected2, setPeriodo2] = React.useState("");
  const [tipoDocumentoSelected, setTipoDocumento] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  const [categorySelected, setCategory] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  const [integranteSelected, setIntegrante] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  const [discSelected, setDisc] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  var tipoDocumentoList = [
    { id: "FC", name: "Factura" },
    { id: "RE", name: "Recibo" },
    { id: "TK", name: "Ticket" },
  ];

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const controller = new AbortController();

  const obtenerCBUS = async () => {
    return myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/GetContractCbus?contra=" +
        user.user.data.Customers.socialSecurityNumber.split(" ")[0] +
        "&idCustomer=" +
        user.user.data.Customers.idCustomer,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });
  };

  const obtenerTipos = async () => {
    return myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA + "/psafiliates/api/v1/GetReinTipos",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });
  };

  const obtenerTiposAdjuntos = async () => {
    return myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/GetReinTiposAdjuntos",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });
  };

  const obtenerDiscapacidad = async (contra, inte) => {
    return myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/GetPatientDisc?contra=" +
        contra +
        "&inte=" +
        inte +
        "&idCustomer=" +
        user.user.data.Customers.idCustomer,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });
  };

  const obtenerGrupoFamiliar = async () => {
    return myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/familiargroup/getfamiliargroupbyid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
      },
    });
  };

  const obtenerBancos = async () => {
    return myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA + "/psafiliates/api/v1/getbanksrein",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      params: {
        contra: user.user.data.Customers.socialSecurityNumber.split(" ")[0],
        idCustomer: user.user.data.Customers.idCustomer,
      },
    });
  };

  const obtenerDatos = async () => {
    try {
      if (sessionStorage.getItem("banks") !== null) {
        const responseBanks = JSON.parse(sessionStorage.getItem("banks"));
        setBanks(responseBanks);
      } else {
        var listaBancos = await obtenerBancos();
        const responseBanks = listaBancos.data.data.ds.map((value, index) => {
          return { id: value.deno_bco, name: value.deno_bco };
        });

        setBanks(responseBanks);
        sessionStorage.setItem("banks", JSON.stringify(responseBanks));
      }
      //formik.resetForm();
    } catch (exception) {}

    try {
      var listaCbus = await obtenerCBUS();
      const responseContracts = listaCbus.data.data.ds.map((value, index) => {
        return {
          id: value.cbu,
          name: value.cbu + " " + value.referencia_cuenta,
        };
      });

      setContracts(responseContracts);
      setCbu(responseContracts[0] ?? []);
      formik.setFieldValue("cbu", responseContracts[0].id ?? null);
    } catch (exception) {}

    try {
      var listaTipos = await obtenerTipos();
      setTemporalCategories([...listaTipos.data.data.ds]);
    } catch (exception) {}

    try {
      var listaTiposAdjuntos = await obtenerTiposAdjuntos();
      setTemporalTiposAdjuntos([...listaTiposAdjuntos.data.data.ds]);
    } catch (exception) {}

    try {
      var listaGrupoFamiliar = await obtenerGrupoFamiliar();
      let filterFamilyGroup = familyGroupFilterPSHB(
        listaGrupoFamiliar.data.data.MergedFamilyGroup,
        listaGrupoFamiliar.data.data.PrestaciFamilyGroup
      );
      let familyGroup = [];
      try {
        familyGroup = filterFamilyGroup.map((family) => {
          return {
            id: family.credential.split(" ")[1],
            name: family.fullName,
            socialSecurityNumber: family.credential,
            dni: family.dni
          };
        });
      } catch (error) {}

      var temporalFamilyGroup = [
        {
          id: user.user.data.Customers.socialSecurityNumber.split(" ")[1],
          name:
            user.user.data.Customers.firstName +
            " " +
            user.user.data.Customers.lastName,
          socialSecurityNumber: user.user.data.Customers.socialSecurityNumber,
          dni: user.user.data.Customers.dni
        },
        ...familyGroup,
      ];

      // Filtrar duplicados
      try {
        temporalFamilyGroup = temporalFamilyGroup.reduce((acc, current) => {
          const x = acc.find(
            (item) =>
              item.dni === current.dni
          );
          if (!x) {
            acc.push(current);
          }
          return acc;
        }, []);
      } catch (error) {}

      setFamilyGroup(temporalFamilyGroup);
      setIntegrante({
        id: user.user.data.Customers.socialSecurityNumber.split(" ")[1],
        name:
          user.user.data.Customers.firstName +
          " " +
          user.user.data.Customers.lastName,
        numero: user.user.data.Customers.socialSecurityNumber.split(" ")[1],
      });
    } catch (exception) {}

    try {
      var esDiscapacidad = await obtenerDiscapacidad(
        user.user.data.Customers.socialSecurityNumber.split(" ")[0],
        user.user.data.Customers.socialSecurityNumber.split(" ")[1]
      );
      if (esDiscapacidad.data.data.aplica_disca === 0) {
        setDiscList([{ id: "NO", name: "NO" }]);
      } else {
        setDiscList([
          { id: "SI", name: "SI" },
          { id: "NO", name: "NO" },
        ]);
      }
    } catch (exception) {}
  };

  React.useEffect(() => {
    obtenerDatos();
  }, []);

  const limpiarFormulario = () => {
    formik.resetForm();
    setDisc({
      id: "",
      name: "Seleccionar",
    });

    setCategories([]);
    setCategory({
      id: "",
      name: "Seleccionar",
    });

    setTipoDocumento({
      id: "",
      name: "Seleccionar",
    });

    setPeriodo(dateFormat);
    setPeriodo2(dateFormatPeriod);

    setTiposAdjuntos([]);

    // setCbu({
    //     id: "",
    //     name: "Seleccionar"
    // })

    obtenerCBUSAnadidos();

    ref_observacion.current.value = "";
  };

  const seleccionarCBU = (id, name, text) => {
    setCbu({ id: id, name: text });
    formik.setFieldValue("cbu", id);
    //formik.settouched("cbu");
  };

  const seleccionarPeriodo = (e) => {
    setPeriodo(e.target.value);
    formik.setFieldValue("orden_fecha", e.target.value);
    //formik.settouched("orden_fecha");
  };

  const seleccionarPeriodo2 = (e) => {
    let value = e.target.value;

    // Eliminar cualquier carácter no numérico o "/"
    value = value.replace(/[^0-9\/]/g, "");

    // Forzar el formato MM/AAAA mientras se escribe
    if (value.length === 2 && !value.includes("/")) {
      value = value + "/";
    }

    // Limitar la longitud a 7 caracteres (MM/AAAA)
    if (value.length > 7) {
      value = value.substring(0, 7);
    }

    setPeriodo2(value);
    formik.setFieldValue("orden_periodo", value);
  };

  const anadirComprobanteNumero = (e) => {
    let { value } = e.target;
    if (tipoDocumentoSelected.id == "FC") {
      let expresionContinua = /^[a-cA-C]{1}\d{0,13}$/;

      if (!expresionContinua.test(value)) {
        value = value.substring(0, value.length - 1);
      }
    } else if (tipoDocumentoSelected.id == "RE") {
      let expresionContinua = /^[rR]{1}\d{0,13}$/;

      if (!expresionContinua.test(value)) {
        value = value.substring(0, value.length - 1);
      }
    } else {
      let expresionContinua = /^[xX]{1}\d{0,13}$/;

      if (!expresionContinua.test(value)) {
        value = value.substring(0, value.length - 1);
      }
    }

    formik.setFieldValue("compro_nro", value);
  };

  const seleccionarTipoDocumento = (id, name, text) => {
    setTipoDocumento({ id, name: text });
    formik.setFieldValue("compro_tipo", id);
    //formik.settouched("compro_tipo");
  };

  const seleccionarCategoria = (id, name, text) => {
    setCategory({ id, name: text });
    formik.setFieldValue("tipo", id);
    //formik.settouched("tipo");
    var tipos = temporalTiposAdjuntos.filter(
      (tipo) => tipo.rei_tipo === id && tipo.rei_adjunto !== 20
    );
    setTiposAdjuntos([...tipos]);
    setArchivos([]);
  };

  const seleccionarIntegrante = async (id, name, text) => {
    formik.setFieldValue("integrante", id);
    setDisc({
      id: "",
      name: "Seleccionar",
    });

    setCategories([]);
    setCategory({
      id: "",
      name: "Seleccionar",
    });

    var credential = familygroup.find(
      (family) => family.socialSecurityNumber.split(" ")[1] === id
    )?.socialSecurityNumber;
    var partCredential = credential.split(" ")?.filter((x) => x != "");
    setIntegrante({ id, name: text, numero: partCredential[1] });
    if (partCredential.length === 2) {
      var esDiscapacidad = await obtenerDiscapacidad(
        partCredential[0],
        partCredential[1]
      );
      if (esDiscapacidad.data.data.aplica_disca === 0) {
        setDiscList([{ id: "NO", name: "NO" }]);
      } else {
        setDiscList([
          { id: "SI", name: "SI" },
          { id: "NO", name: "NO" },
        ]);
      }
    }
  };

  const seleccionarDiscapacidad = (id, name, text) => {
    setMostrarTooltipDiscapacidad(false);
    setDisc({ id: id, name: text });
    formik.setFieldValue("sucursal", id === "SI" ? 14 : 13);
    //formik.settouched("sucursal");
    var ca = temporalCategories
      .filter((category) => {
        if (id === "NO") {
          return category.disca !== "S";
        } else {
          return true;
        }
      })
      .map((value, index) => {
        return { id: value.tipo, name: value.deno };
      });

    setCategories([...ca]);
  };

  const validarArchivo = async (file) => {
    return new Promise((resolve, reject) => {
      const data = {
        content: file.base64,
        fileName: file.fileName,
        refoundNumber: "0",
        sucursal: discSelected.id === "SI" ? "14" : "13",
        idCustomer: user.user.data.Customers.idCustomer,
        idSap: user.user.data.Customers.idSap,
      };

      myAxios({
        url:
          process.env.REACT_APP_GATEWAY_QA +
          "/PSAfiliates/Api/v1/ValidateRefoundFilesBase64",
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      })
        .then((response) => {
          let isValid = !response.data.error;
          if (!isValid) {
            tiposAdjuntos.map((item) => {
              if (item.rei_adjunto == file.rei_adjunto) {
                item.error = true;
              } else {
                item.error = false;
              }

              return item;
            });

            setTiposAdjuntos(tiposAdjuntos);
          }
          resolve(isValid);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const subirArchivo = async (file, numeroReintegro) => {
    return new Promise((resolve, reject) => {
      const data = {
        content: file.base64,
        fileName: file.fileName,
        refoundNumber: `${numeroReintegro}`,
        sucursal: discSelected.id === "SI" ? "14" : "13",
        idCustomer: user.user.data.Customers.idCustomer,
        idSap: user.user.data.Customers.idSap,
      };

      myAxios({
        url:
          process.env.REACT_APP_GATEWAY_QA +
          "/PSAfiliates/Api/v1/UploadRefoundFilesBase64",
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      })
        .then((response) => {
          try {
            fetchLogs({
              idLogType: 100,
              idLog: 201,
              source: "pshb",
              email: user.user.data.Customers.email,
              dni: user.user.data.Customers.dni ?? "",
              credencial: user.user.data.Customers.credencial ?? "",
              description:
                "El usuario " +
                user.user.data.Customers.lastName +
                " " +
                user.user.data.Customers.firstName +
                "  cargó correctamente los archivos del reintegro N° " +
                numeroReintegro +
                ".",
            });
          } catch (logError) {
            console.error("Error al cargar archivos de reintegro:", logError);
          }
          resolve(!response.data.data.error);
        })
        .catch((error) => {
          reject({ file, error });
        });
    });
  };

  const subirTodosArchivos = (numeroReintegro) => {
    var promisesUpload = Object.values(archivos).map((value, index) =>
      subirArchivo(value, numeroReintegro)
    );
    Promise.all(promisesUpload)
      .then((valuesUpload) => {
        var pasoSubir = valuesUpload.every((valueUpload, index) => valueUpload);
        if (pasoSubir) {
          // formik.resetForm();
          // setDisc({
          //     id: "",
          //     name: "Seleccionar"
          // });

          // setCategories([])
          // setCategory({
          //     id: "",
          //     name: "Seleccionar"
          // })

          // setTipoDocumento({
          //     id: "",
          //     name: "Seleccionar"
          // })
          limpiarFormulario();
          setResponseData({ message: "Datos enviados", error: false });
          MessageToggle.onToggle();
        } else {
          setResponseData({
            message: "No se pudieron subir los archivos",
            error: false,
          });
          MessageToggle.onToggle();
        }
      })
      .catch((errorSubida) => {
        setResponseData({
          message: "Error subiendo los archivos: " + errorSubida.file.fileName,
          error: true,
        });
        MessageToggle.onToggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const convertToDateString = (monthYear) => {
    // Dividir el string por "/"
    const [month, year] = monthYear.split("/").map(Number);

    // Validar que el mes y el año sean válidos
    if (!month || !year || month < 1 || month > 12) {
        throw new Error("Formato inválido. Usa MM/YYYY.");
    }

    // Crear un objeto Date (JavaScript usa 0 para enero, por lo que restamos 1)
    const date = new Date(year, month - 1, 1); // Día 1 del mes

    // Formatear la fecha como AAAA-MM-DD
    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const guardarReintegro = (values) => {
    var valor_presentado = values.presen_total;

    if (valor_presentado.indexOf(".") === -1) {
      valor_presentado = valor_presentado + ".00";
    }

    // valor_presentado=valor_presentado.replaceAll(/[^\d,]+/g, '').replace(',','.');

    const payload = {
      sucursal: discSelected.id === "SI" ? "14" : "13",
      idCustomer: user.user.data.Customers.idCustomer,
      contrato: user.user.data.Customers.socialSecurityNumber.split(" ")[0],
      integrante: integranteSelected.numero,
      cbu: values.cbu.replaceAll("-", ""),
      mail: user.user.data.Customers.email,
      tipo: values.tipo,
      obser: values.obser,
      compro_tipo: values.compro_tipo,
      compro_nro: values.compro_nro,
      cae: values.cae,
      cae_vto: values.cae_vto,
      presen_total: valor_presentado,
      efe_cuit: values.efe_cuit.replaceAll("-", ""),
      orden_fecha: values.orden_fecha,
      orden_periodo: convertToDateString(values.orden_periodo)
    };

    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/setrefoundfromportalnewportal",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      params: payload,
    })
      .then((response) => {
        //    console.log("RESPONSE GUARDAR REINTEGRO",response);
        let num_reintegro = response.data.data.ds[0].num_reintegro;
        if (num_reintegro != null) {
          try {
            fetchLogs({
              idLogType: 100,
              idLog: 202,
              source: "pshb",
              email: user.user.data.Customers.email,
              dni: user.user.data.Customers.dni ?? "",
              credencial: user.user.data.Customers.credencial ?? "",
              description:
                "El usuario " +
                user.user.data.Customers.lastName +
                " " +
                user.user.data.Customers.firstName +
                "  cargó correctamente la cabecera  del reintegro N° " +
                num_reintegro +
                ".",
            });
          } catch (logError) {
            console.error("Error al cargar cabecera de reintegro:", logError);
          }
          subirTodosArchivos(num_reintegro);
        } else {
          setLoading(false);
          setResponseData({
            message: response.data.data.ds[0].mensaje,
            error: true,
          });
          MessageToggle.onToggle();
        }
      })
      .catch((error) => {
        setLoading(false);
        setResponseData({
          message: "Error al crear el reintegro",
          error: true,
        });
        MessageToggle.onToggle();
      })
      .finally(() => {});
  };

  const validarTodosArchivos = (valores) => {
    var promises = Object.values(archivos).map((value, index) =>
      validarArchivo(value)
    );
    Promise.all(promises)
      .then((values) => {
        var paso = values.every((value, index) => value);
        if (paso) {
          //subirTodosArchivos();
          guardarReintegro(valores);
        } else {
          setLoading(false);
          setResponseData({ message: "Archivos invalidos", error: true });
          MessageToggle.onToggle();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      cbu: "",
      presen_total: "",
      cae: "",
      cae_vto: "",
      integrante: user.user.data.Customers.idSap,
      orden_fecha: dateFormat,
      orden_periodo: dateFormatPeriod,
      compro_nro: "",
      compro_tipo: "",
      sucursal: "",
      tipo: "",
      efe_cuit: "",
      obser: "",
    },
    validate,
    onSubmit: (values) => {
      // console.log("VALUES",values);
      setLoading(true);
      var obligatorios = tiposAdjuntos
        .filter((tipoAdjunto, indiceAdjunto) => tipoAdjunto.obligatorio === "S")
        .filter((value, index) => {
          var existe = Object.values(archivos).some(
            (archivo, indiceArchivo) => {
              return archivo.rei_adjunto === value.rei_adjunto;
            }
          );

          return !existe;
        });

      if (obligatorios.length > 0) {
        setLoading(false);
        setResponseData({
          message: "Por favor carga los archivos obligatorios",
          error: true,
        });
        MessageToggle.onToggle();
        return;
      }

      tiposAdjuntos.map((item) => {
        item.error = false;
        return item;
      });

      setTiposAdjuntos(tiposAdjuntos);

      validarTodosArchivos(values);

      //   alert(JSON.stringify(values, null, 2));
    },
  });

  const changeFile = async (file, name, id) => {
    var base64 = await toBase64(file);
    base64 = base64.split("base64,")[1];
    setArchivos({
      ...archivos,
      [id]: { base64, rei_adjunto: id, fileName: file.name, name },
    });
  };

  const cambiarValorPresentado = (e) => {
    let { value } = e.target;
    value = value.replaceAll(",", ".");
    const regexNumber = /^[0-9]$/;

    // value=value.replaceAll("$","")

    if (
      !regexNumber.test(value.at(-1)) &&
      e.keyCode !== 45 &&
      value.at(-1) !== "."
    ) {
      value = value.substring(0, value.length - 1);
    }

    let cantidadPuntos = value
      .split("")
      ?.filter((item) => item === ".")?.length;

    if (cantidadPuntos > 1) {
      value = value.substring(0, value.length - 1);
    }

    // value=value.replaceAll(".",",")
    e.target.value = value;

    if (value.split(".")[0].length <= 7) {
      formik.setFieldValue("presen_total", value);
      formik.handleChange(e);
    }

    // var newValue=new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(e.target.value);
  };

  const tooltipValorPresentado = (props) => (
    <Tooltip {...props}>
      Monto por el cual solicita reintegro sin $ ni separador de miles (,).
      (Para ingresar decimales debe de indicar un .
    </Tooltip>
  );

  const tooltipDocumento = (props) => (
    <Tooltip {...props}>
      Incluir al inicio la letra correspondiente (si es Factura, incluir la
      letra A, B o C, si es Recibo, la letra R, y si es Ticket, la letra X),
      seguida de los 13 dígitos.
    </Tooltip>
  );

  const tooltipFecha = (props) => (
    <Tooltip {...props}>
      Corresponde a la fecha de la Factura, Recibo o Ticket.
    </Tooltip>
  );

  const tooltipDiscapacidad = (props) => (
    <Tooltip className="tooltip-disc" {...props}>
      Estimado afiliado, le informamos que, para solicitar el reintegro por el
      ajuste correspondiente al incremento RETROACTIVO del nomenclador nacional
      de discapacidad, deberá presentar en el próximo periodo facturado, un item
      en la misma factura que indique: diferencia retroactiva por incremento NN
      discapacidad según resolución conjunta X/XXXX, periodo XX/XXXX con el
      valor que corresponda.
    </Tooltip>
  );

  const tooltipCuit = (props) => (
    <Tooltip {...props}>
      El prestador es la institución o el profesional que emitió el comprobante
      a presentar. (Solo se permiten valores numericos)
    </Tooltip>
  );

  const cambiarCuit = (e) => {
    const { value } = e.target;
    if ((value.length === 2 || value.length === 11) && e.keyCode !== 45) {
      e.target.value = value + "-";
    }

    const regexNumber = /^[0-9]$/;
    if (!regexNumber.test(value.at(-1)) && e.keyCode !== 45) {
      e.target.value = value.substring(0, value.length - 1);
    }

    formik.handleChange(e);
  };

  const obtenerCBUSAnadidos = async () => {
    try {
      var listaCbus = await obtenerCBUS();
      const responseContracts = listaCbus.data.data.ds.map((value, index) => {
        return {
          id: value.cbu,
          name: value.cbu + " " + value.referencia_cuenta,
        };
      });

      setContracts(responseContracts);
      setCbu(responseContracts[0] ?? []);
      formik.setFieldValue("cbu", responseContracts[0].id ?? null);
    } catch (exception) {}
  };

  return (
    <MessageContext.Provider value={responseData}>
      <section className=" ">
        <CBUComponent
          banks={banks}
          visible={CBUToggle.toggle}
          onToggle={CBUToggle.onToggle}
          obtenerCBUS={obtenerCBUSAnadidos}
          integranteSelected={`${user.user.data.Customers.firstName} ${user.user.data.Customers.lastName}`}
          contrato={user.user.data.Customers.socialSecurityNumber.split(" ")[0]}
        />

        <ModalConfirmChangeComponent
          visible={MessageToggle.toggle}
          onToggle={MessageToggle.onToggle}
        />

        <InformacionComponent
          visible={informacionToggle.toggle}
          onToggle={informacionToggle.onToggle}
        />

        {/* INICIO FORMULARIO */}
        <div className="container p-4">
          <form onSubmit={formik.handleSubmit}>
            {/* TITULO */}
            <div className="row mt-5">
              <div
                className="col-lg-12"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2 className="section_pshb_title">
                  <b>Reintegros</b>
                </h2>
                <a data-tooltip-id="my-tooltip" data-tooltip-content="Ayuda">
                  <ReactTooltip id="my-tooltip" />{" "}
                  <img
                    id="icon-information"
                    onClick={() => informacionToggle.onToggle()}
                    src={InformationIcon}
                    alt="..."
                  ></img>
                </a>
              </div>
            </div>

            <div className="separador-table-green-result"></div>

            <div className="card card-refund b-radius-20 shadow-external">
              <div className="g-0">
                <div className="row">
                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      CBU <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund h-30">
                      <div style={{ width: "100%" }}>
                        <div style={{ position: "relative" }}>
                          <CustomSelect
                            defaultText={
                              cbuSelected.name
                                ? cbuSelected.name
                                : "Ingresar CBU"
                            }
                            optionsList={contracts}
                            type={CUSTOM_SELECT_COLOR_WHITE}
                            width="100%"
                            fontFamily="Objectivity-Medium"
                            fontSize="14px"
                            onChange={seleccionarCBU}
                            name="cbu"
                            error={formik.errors.cbu}
                            close={closeCBU}
                            onClick={clickCBU}
                          />
                        </div>
                      </div>
                      {contracts != null && contracts.length < 1 && (
                        <div
                          className="btn btn-hb-primary-without-border shadow-blue"
                          style={{ zIndex: "20" }}
                          onClick={CBUToggle.onToggle}
                          id="anadir-cbu"
                        >
                          Añadir CBU
                        </div>
                      )}
                    </div>
                    <span className="error-label-pshb">
                      {formik.errors.cbu}
                    </span>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Valor presentado <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund ">
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipValorPresentado}
                      >
                        <div
                          className="d-flex flex-column w-100"
                          onMouseLeave={() => setMoney(false)}
                          onMouseEnter={() => setMoney(true)}
                        >
                          {money ? (
                            <input
                              onPaste={(e) => e.preventDefault()}
                              type="text"
                              data-for="presen_total"
                              onChange={cambiarValorPresentado}
                              onBlur={cambiarValorPresentado}
                              value={formik.values.presen_total}
                              name="presen_total"
                              className={
                                (formik.errors.presen_total &&
                                formik.touched.presen_total
                                  ? "error-data "
                                  : "") +
                                "input-round input-text-pshb shadow-blue w-100"
                              }
                            />
                          ) : (
                            <span
                              style={{ lineHeight: "25px" }}
                              className="input-round input-text-pshb shadow-blue w-100"
                            >
                              {"$" +
                                parseFloat(formik.values.presen_total)
                                  .toLocaleString("es-AR")
                                  .replaceAll("NaN", "")}
                            </span>
                          )}
                          {formik.touched.presen_total ? (
                            <span className="error-label-pshb">
                              {formik.errors.presen_total}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Tipo de comprobante <span className="obligatorio">*</span>
                    </span>

                    <div className="input-icons-date-pshb-refund relative h-30">
                      <CustomSelect
                        defaultText={tipoDocumentoSelected.name}
                        optionsList={tipoDocumentoList}
                        type={CUSTOM_SELECT_COLOR_WHITE}
                        width="100%"
                        fontFamily="Objectivity-Medium"
                        fontSize="14px"
                        onChange={seleccionarTipoDocumento}
                        name="compro_tipo"
                        error={
                          formik.errors.compro_tipo &&
                          formik.touched.compro_tipo
                        }
                        close={closeTipoDocumento}
                        onClick={clickTipoDocumento}
                      />
                    </div>
                    {formik.touched.compro_tipo ? (
                      <span className="error-label-pshb">
                        {formik.errors.compro_tipo}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Nro comprobante <span className="obligatorio">*</span>
                    </span>
                    <OverlayTrigger placement="top" overlay={tooltipDocumento}>
                      <div className="input-icons-date-pshb-refund d-flex flex-column">
                        <input
                          type="text"
                          onPaste={(e) => e.preventDefault()}
                          placeholder="A000000000000"
                          maxLength={14}
                          onChange={anadirComprobanteNumero}
                          onBlur={formik.handleBlur}
                          value={formik.values.compro_nro}
                          name="compro_nro"
                          className={
                            (formik.errors.compro_nro &&
                            formik.touched.compro_nro
                              ? "error-data "
                              : "") +
                            "input-round input-text-pshb shadow-blue w-100"
                          }
                        />
                        {formik.touched.compro_nro ? (
                          <span className="error-label-pshb">
                            {formik.errors.compro_nro}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Fecha del comprobante{" "}
                      <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund relative">
                      <OverlayTrigger placement="top" overlay={tooltipFecha}>
                        <div className="input-icons-date-pshb-refund w-100">
                          {/* <FontAwesomeIcon icon={faCalendar} className="custom-icon-results-date-pshb"/>*/}
                          <input
                            value={periodoSelected}
                            type="date"
                            ref={ref_orden_fecha}
                            name="orden_fecha"
                            className={
                              (formik.errors.orden_fecha ? "error-data " : "") +
                              "input-round shadow-blue w-100 input-date-ios"
                            }
                            onChange={seleccionarPeriodo}
                            placeholder="Elegir fecha"
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Periodo <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund relative">
                      <div className="input-icons-date-pshb-refund w-100">
                        <input
                          type="text"
                          value={periodoSelected2}
                          name="orden_periodo"
                          ref={ref_orden_periodo}
                          maxLength={7}
                          className="input-round shadow-blue w-100 input-date-ios"
                          onChange={seleccionarPeriodo2}
                          placeholder="MM/AAAA"
                          
                        />
                      </div>
                    </div>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Paciente <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund h-30 ">
                      <div style={{ width: "100%" }}>
                        <div style={{ position: "relative" }}>
                          <CustomSelect
                            defaultText={integranteSelected.name}
                            optionsList={familygroup}
                            type={CUSTOM_SELECT_COLOR_WHITE}
                            width="100%"
                            fontFamily="Objectivity-Medium"
                            fontSize="14px"
                            onChange={seleccionarIntegrante}
                            name="integrante"
                            close={closeIntegrante}
                            onClick={clickIntegrante}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-date col-md-6 col-xl-4 col-sm-12">
                    <span className="label-pshb">
                      Reintegro por discapacidad{" "}
                      <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund h-30">
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipDiscapacidad}
                      >
                        <div style={{ width: "100%" }}>
                          <div
                            style={{ position: "relative" }}
                            data-tip={
                              discSelected.name === "no"
                                ? "Para solicitar reintegros por discapacidad, por favor comuniquese con nuestro equipo de atención al cliente al email: discapacidad@hbritanico.com.ar"
                                : ""
                            }
                          >
                            <CustomSelect
                              defaultText={discSelected.name}
                              optionsList={discList}
                              type={CUSTOM_SELECT_COLOR_WHITE}
                              width="100%"
                              fontFamily="Objectivity-Medium"
                              fontSize="14px"
                              onChange={seleccionarDiscapacidad}
                              name="sucursal"
                              error={
                                formik.errors.sucursal &&
                                formik.touched.sucursal
                              }
                              close={closeDiscapacidad}
                              onClick={clickDiscapacidad}
                            />
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                    {formik.touched.sucursal ? (
                      <span className="error-label-pshb">
                        {formik.errors.sucursal}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Categoría reintegro <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund relative h-30">
                      <CustomSelect
                        defaultText={categorySelected.name}
                        optionsList={categories}
                        type={CUSTOM_SELECT_COLOR_WHITE}
                        fontFamily="Objectivity-Medium"
                        width="100%"
                        fontSize="14px"
                        onChange={seleccionarCategoria}
                        name="tipo"
                        error={formik.errors.tipo && formik.touched.tipo}
                        close={closeCategoria}
                        onClick={clickCategoria}
                      />
                    </div>
                    {formik.touched.tipo ? (
                      <span className="error-label-pshb">
                        {formik.errors.tipo}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      CUIT prestador <span className="obligatorio">*</span>
                    </span>

                    <div className="input-icons-date-pshb-refund d-flex flex-column">
                      <OverlayTrigger placement="top" overlay={tooltipCuit}>
                        <input
                          type="text"
                          onPaste={(e) => e.preventDefault()}
                          data-tip="Solo se permiten valores númericos"
                          placeholder="00-00000000-0"
                          onChange={cambiarCuit}
                          onBlur={cambiarCuit}
                          value={formik.values.efe_cuit}
                          name="efe_cuit"
                          maxLength={13}
                          className={
                            (formik.errors.efe_cuit && formik.touched.efe_cuit
                              ? "error-data "
                              : "") +
                            "input-round input-text-pshb shadow-blue w-100"
                          }
                        />
                      </OverlayTrigger>
                      {formik.touched.efe_cuit ? (
                        <span className="error-label-pshb">
                          {formik.errors.efe_cuit}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                {tipoDocumentoSelected.id === "FC" ? (
                  <div className="row">
                    <div className="section-date col-md-6 col-xl-4">
                      <span className="label-pshb">C.A.E Número</span>
                      <div
                        className="input-icons-date-pshb-refund "
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <input
                          type="text"
                          maxLength={14}
                          data-tip="Verifique si tiene CAE al pie de la factura."
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.cae}
                          name="cae"
                          className={
                            (formik.errors.cae && formik.touched.cae
                              ? "error-data "
                              : "") +
                            "input-round input-text-pshb shadow-blue w-100"
                          }
                        />
                        {formik.touched.cae ? (
                          <span className="error-label-pshb">
                            {formik.errors.cae}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="section-date col-md-4 col-xl-4">
                      <span className="label-pshb">C.A.E Vencimiento</span>
                      <div className="input-icons-date-pshb-refund me-3 w-100">
                        {/* <FontAwesomeIcon icon={faCalendar} className="custom-icon-results-date-pshb" /> */}
                        <input
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.cae_vto}
                          name="cae_vto"
                          className={
                            (formik.errors.cae_vto && formik.touched.cae_vto
                              ? "error-data "
                              : "") +
                            "input-round input-text-pshb shadow-blue w-100"
                          }
                          placeholder="Elegir fecha"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="row">
                  <div className="section-date col-md-12">
                    <span className="label-pshb">Descripción</span>
                    <div className="input-icons-date-pshb-refund ">
                      <textarea
                        ref={ref_observacion}
                        name="obser"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="input-login input-text-pshb shadow-blue w-100"
                        rows="10"
                      >
                        {formik.values.obser}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  {tiposAdjuntos.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0px",
                      }}
                    >
                      <span id="aclaracion-archivos">
                        Los archivos a subir deberían ser de tipo{" "}
                        <span className="enfasis">PDF</span>,{" "}
                        <span className="enfasis">PNG</span>,{" "}
                        <span className="enfasis">JPG</span> o{" "}
                        <span className="enfasis">JPEG</span>. Con un peso
                        máximo de 5Mb
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {tiposAdjuntos.map((value, index) => {
                    return (
                      <div className="col-md-12 col-lg-5 col-sm-12">
                        <span className="label-file">{value.adjunto}</span>
                        {value.obligatorio === "S" ? (
                          <span style={{ color: "red", fontSize: "20px" }}>
                            {" "}
                            *
                          </span>
                        ) : (
                          ""
                        )}
                        <FileInput
                          key={value.rei_adjunto}
                          onChange={(file) =>
                            changeFile(file, value.adjunto, value.rei_adjunto)
                          }
                        />
                        {value.error ? (
                          <div
                            style={{
                              color: "red",
                              fontFamily: "Objectivity-Regular",
                              fontSize: "12px",
                              marginTop: "10px",
                              textAlign: "center",
                            }}
                          >
                            Intente con otro archivo, de ser posible en formato
                            PDF.
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="row">
                  <div className="col-md-12 text-center mt-4">
                    <Button
                      type="submit"
                      disabled={loading}
                      className="btn btn-hb-primary-without-border shadow-blue"
                    >
                      {!loading ? (
                        "Confirmar"
                      ) : (
                        <>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* FIN FORMULARIO */}
        <div className="footer-pshb"></div>
      </section>
    </MessageContext.Provider>
  );
};

export default AddRefundComponent;

import React, { useContext, useState } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
// import logo from '../../../assets/img/pshb/logo.jpg'

import bgImageLoginPSHB from './../../../assets/img/hb/test.jpg'
import logo from "../../../assets/img/logos/logo-plan.png";
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithGoogle,
  signInWithFacebook,
  auth,
  providerFacebook,
  providerGoogle,
} from "../../../utils/firebase";
import {
  getAuth,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithCredential,
} from "firebase/auth";
import Swal from "sweetalert2";
import axios from "axios";
import { emailValidator } from "../../../utils/hooks/regex";
import GoogleAuthButton from "../../../containers/google";
import FacebookAuthButton from "../../../containers/facebook";
import MockUser from "../../../utils/mock-user/mock-user";
import Overlay from "../../../components/common/overlay";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useProgressiveImage from "../../../hooks/useProgressiveImage";
import { fetchLogs } from "../../../utils/fetch";


const LoginPSHB = (props) => {
  const loadedBgImage = useProgressiveImage(bgImageLoginPSHB)

  const [loader, setLoader] = useState(false);
  const [loaderGoogle, setLoaderGoogle] = useState(false);
  const [loaderFacebook, setLoaderFacebook] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [googleButtonDisabled, setGoogleButtonDisabled] = useState(false);
  const [facebookButtonDisabled, setFacebookButtonDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const navigate = useNavigate();

  const {
    sitePSHB,
    setSitePSHB,
    userPSHB,
    setUserPSHB,
    user,
    setUser,
    setInitialUser,
    socialMediaModal,
  } = useContext(UserGlobalContextMemorySpace);

  const disabledAllButtons = () => {
    setButtonDisabled(true);
    setGoogleButtonDisabled(true);
    setFacebookButtonDisabled(true);
  };

  const enableAllButtons = () => {
    setButtonDisabled(false);
    setGoogleButtonDisabled(false);
    setFacebookButtonDisabled(false);
  };

  const handleLoginPSHB = (event) => {
    event.preventDefault();
    // setTimeout(() => {
    //     enableAllButtons();
    //     setUserPSHB(false);
    //     setUser({user: "HB User"})
    //     setLoader(false)
    // }, 3000);
    // setUserPSHB(false);
    // setUser({user: "HB User"})
    setErrorMsg("");
    if (!email || !password || socialMediaModal) {
      // Estilos para marcar al usuario
      if (!socialMediaModal) {
        setErrorMsg(
          "* Por favor ingresa usuario y contraseña para iniciar sesión."
        );
      }
    } else {
      if (emailValidator(email)) {
        setErrorMsg("* Por favor ingresa un formato de email valido");
      } else {
        disabledAllButtons();
        setLoader(true);
        let body = {
          email: email,
          password: password,
          pshb: true,
        };

        let date = new Date().getTime() + 60 * 60000;
        localStorage.clear();

        axios({
          url:
            process.env.REACT_APP_STRAPI_QA +
            process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN,
          method: "POST",
          data: body,
        })
          .then((response) => {
            if (response.data.responseCode == 200) {
              if (response.data.data.Customers.pshb) {
                setLoader(false);
                enableAllButtons();

              try {
                  response.data.data.Customers.socialSecurityNumber = response.data.data.Customers.credencial;
                  response.data.data.Customers.socialSecurity = "40080/000";
              } catch (e) {
                  console.log("SSN Missing")
              }
                setInitialUser(response.data.data.Customers);
                setUser({ user: response.data });
                setUserPSHB(true);
                localStorage.setItem("enc", response.data.data.enc);
                localStorage.setItem("enc_session", date);

                // const redirect = sessionStorage.getItem("redirect");
                // if (redirect) {
                //   sessionStorage.removeItem("redirect");
                //   navigate(redirect, { replace: true });
                // }
              } else {
                setLoader(false);
                enableAllButtons();
                Swal.fire({
                  allowOutsideClick: false,
                  icon: "info",
                  title:
                    '<h5 style="color: #224d7c">Plan de Salud no encontrado.</h5>',
                  text: "No encontramos un Plan de Salud del Hospital Britanico asociado a la casilla de mail indicada, comunicate con nuestro equipo de ventas para solicitar un Plan.",
                  color: "#224d7c",
                  confirmButtonColor: "#224d7c",
                  confirmButtonText: "Ventas",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location = "https://promociones.hbritanico.com.ar/";
                  }
                });
              }
            } else if (response.data.responseCode == 400) {
              if(response.data.data.type==="UserNotValidated")
              {    
                try {    
                  fetchLogs({
                    idLogType: 1,
                    idLog: 15,     
                    source: "pshb",         
                    email: email,
                    dni: "",
                    credencial: "",
                    description:"Han intentado acceder al Portal Web con el correo " + email + " pero el email no ha sido validado",                  
                  });
                } catch (logError) {
                  console.error("Error al acceder al portal web:", logError);
                }
              }else if(response.data.data.type==="UserNoExist")
              {
                try {
                  fetchLogs({
                    idLogType: 1,
                    idLog: 15,  
                    source: "pshb",             
                    email: email,
                    dni: "",
                    credencial: "",
                    description:"Han intentado acceder al Portal Web con el correo " + email + " pero el email no existe",                  
                  });
                } catch (logError) {
                  console.error("Error al acceder al portal web:", logError);
                }
              }
              setLoader(false);
              enableAllButtons();
              SwalMessage(
                false,
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                response.data.message
              );
            } else {
              setLoader(false);
              enableAllButtons();
              SwalMessage(
                false,
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                "Lo sentimos. Estamos trabajando para solucionar problemas técnicos en nuestro sitio. Por favor, intente nuevamente más tarde."
              );
            }
          })
          .catch((error) => {
            setLoader(false);
            enableAllButtons();
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Lo sentimos. Estamos trabajando para solucionar problemas técnicos en nuestro sitio. Por favor, intente nuevamente más tarde."
            );
          });
      }
    }
  };

  const changeStack = () => {
    setUserPSHB(!userPSHB);
  };

  // const ByPassLogin = () =>{
  //     setUserPSHB(true);
  //     console.log({user: MockUser()})
  //     setUser({user: MockUser()})
  // }

  const SwalMessage = (allowOutsideClick, icon, title, text) => {
    Swal.fire({
      allowOutsideClick: allowOutsideClick,
      icon: icon,
      title: title,
      text: text,
      color: "#224d7c",
      confirmButtonColor: "#224d7c",
      confirmButtonText: "Continuar",
    });
  };

  const handlerSignInWithGoogle = async (event) => {
    event.preventDefault();
    disabledAllButtons();
    setLoaderGoogle(true);
    let idToken = await signInWithPopup(auth, providerGoogle)
      .then((result) => {
        //const idToken = result._tokenResponse.idToken;
        return result;
      })
      .catch((error) => {
        setLoaderGoogle(false);
        enableAllButtons();
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Google, por favor intenta con tu usuario y contraseña."
        );
      });
    console.log(idToken._tokenResponse.idToken);
    axios({
      url:
        process.env.REACT_APP_STRAPI_QA +
        process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN_SOCIALMEDIA,
      method: "POST",
      data: { idToken: idToken._tokenResponse.idToken },
    })
      .then((response) => {
        setLoaderGoogle(false);
        enableAllButtons();
        console.log(response);
        if (response.data.error) {
          SwalMessage(
            false,
            "warning",
            '<h5 style="color: #224d7c"></h5>',
            "Detectamos un problema en el inicio de sesión con Google, por favor intenta con tu usuario y contraseña."
          );
        } else {
          if (response.data.result.responseCode == 200) {
            setUserPSHB(true);
            setUser({ user: response.data });
          } else if (response.data.result.responseCode == 400) {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              response.data.result.message
            );
          } else {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Detectamos un problema en el inicio de sesión con Google, por favor intenta con tu usuario y contraseña."
            );
          }
        }
      })
      .catch((error) => {
        setLoaderGoogle(false);
        enableAllButtons();
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Google, por favor intenta con tu usuario y contraseña."
        );
      });
  };

  const handlerSignInWithFacebook = async (event) => {
    event.preventDefault();
    disabledAllButtons();
    setLoaderFacebook(true);
    let idToken = await signInWithPopup(auth, providerFacebook)
      .then((result) => {
        //const idToken = result._tokenResponse.idToken;
        return result;
      })
      .catch((error) => {
        enableAllButtons();
        setLoaderFacebook(false);
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
        );
      });
    console.log(idToken._tokenResponse.idToken);
    axios({
      url:
        process.env.REACT_APP_STRAPI_QA +
        process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN_SOCIALMEDIA,
      method: "POST",
      data: { idToken: idToken._tokenResponse.idToken },
    })
      .then((response) => {
        enableAllButtons();
        setLoaderFacebook(false);
        console.log(response);
        if (response.data.error) {
          SwalMessage(
            false,
            "warning",
            '<h5 style="color: #224d7c"></h5>',
            "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
          );
        } else {
          if (response.data.result.responseCode == 200) {
            setUserPSHB(false);
            setUser({ user: response.data });
          } else if (response.data.result.responseCode == 400) {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              response.data.result.message
            );
          } else {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
            );
          }
        }
      })
      .catch((error) => {
        enableAllButtons();
        setLoaderFacebook(false);
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
        );
      });
  };

  return (
    <>
      {loader ? <Overlay /> : null}
      <section>
        <form onSubmit={handleLoginPSHB}>
          <div
            className="d-flex align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="div-login">
              <div className="d-flex flex-column align-items-center">
                <img className="img-logo" src={logo}></img>
                <div className="br"></div>
                <h5 className="default-fontColor">Iniciar Sesión</h5>
                <div className="br"></div>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="input-login shadow-blue"
                  placeholder="E-mail"
                ></input>
                <div className="br"></div>
                <div
                  className="input-login shadow-blue"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <input
                    style={{
                      width: "100%",
                      height: "96%",
                      paddingLeft: "2vh",
                      paddingRight: "2vh",
                      borderRadius: "20px",
                    }}
                    className="custom-input-login"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Contraseña"
                  ></input>
                  <FontAwesomeIcon
                    style={{ right: "12px", position: "absolute" }}
                    icon={passwordShown ? faEyeSlash : faEye}
                    onClick={() => setPasswordShown((previus) => !previus)}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center hr-box">
                  <div>
                    <hr />
                  </div>
                  <div className="dot"></div>
                  <div>
                    <hr />
                  </div>
                </div>
                <button
                  style={{ display: "none" }}
                  onClick={() => {
                    console.log("none");
                  }}
                ></button>
                {/* <div className="button-div">
                                <i className="bi bi-google" style={{color: "red", fontSize:"23px"}}></i>
                                <button disabled={googleButtonDisabled} onClick={handlerSignInWithGoogle}>
                                    {loaderGoogle ? 
                                        <>  
                                            <div style={{marginLeft: "-42px"}}>
                                                <span className="spinner-grow spinner-grow-sm"></span>
                                                <span className="spinner-grow spinner-grow-sm"></span>
                                                <span className="spinner-grow spinner-grow-sm"></span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            Iniciar Sesión con Google
                                        </>
                                    }
                                </button>
                            </div> */}
                {/* <div className='br'></div> */}
                {/* <div className="button-div">
                                <i className="bi bi-facebook" style={{color: "#224d7c", fontSize:"23px"}}></i>
                                <button disabled={facebookButtonDisabled} onClick={handlerSignInWithFacebook}>
                                    {loaderFacebook ? 
                                        <>
                                            <div style={{marginLeft: "-42px"}}>
                                                <span className="spinner-grow spinner-grow-sm"></span>
                                                <span className="spinner-grow spinner-grow-sm"></span>
                                                <span className="spinner-grow spinner-grow-sm"></span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            Iniciar Sesión con Facebook
                                        </>
                                    }
                                </button>
                            </div> */}
                {/* <FacebookAuthButton pshb={true} /> */}
                <div className="br"></div>
                <div style={{}} className="googleBox">
                  <GoogleAuthButton pshb={true}></GoogleAuthButton>
                </div>
                <div className="br"></div>
                <Link
                  to={
                    "/password-recovery"
                  }
                  className="link-noStyle"
                >
                  ¿Olvidaste tu contraseña?
                </Link>
                <div className="br"></div>
                {/* <button disabled={true} type='submit' className="button-continuar shadow-blue">
                                {loader ? 
                                    <>
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                    </>
                                    :
                                    <>Continuar</>
                                }
                            </button> */}
                {loader ? (
                  <button
                    disabled={true}
                    type="submit"
                    className="button-loader shadow-blue"
                  >
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                  </button>
                ) : (
                  <input
                    disabled={buttonDisabled}
                    className="button-continuar shadow-blue"
                    type="submit"
                    value="Continuar"
                  />
                )}
                {errorMsg ? <div className="br"></div> : null}
                <p style={{ color: "red", fontWeight: "500" }}>{errorMsg}</p>
                <Link
                  to={
                    "/register"
                  }
                  className="link-noStyle"
                >
                  Registrarse
                </Link>
              </div>
            </div>
            <div className="bg-image-pshb" style={{ backgroundImage: `url(${loadedBgImage || ''})` }}>
              <div className="bg-image-text">
                <div className="bg-image-text">
                  <div className="transbox">
                    <h1>Bienvenido a mi PSHB Online</h1>
                    <h5>
                      Accedé a tu credencial digital, descargá y pagá tu
                      factura, cargá tus reintegros, realizá trámites y
                      consultas.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default LoginPSHB;
